<!-- 
	This is the login page, it uses the dashboard layout in:
	"./layouts/Default.vue" .
 -->

<template>
	<div>

		<!-- Sign Up Image And Headings -->
		<div class="sign-up-header" style="background-image: url('images/bg-signup.jpg')">
			<div class="content">
				<h1 class="mb-5">登录</h1>
				<p class="text-lg">输入手机号登录</p>
			</div>
		</div>
		<!-- / Sign Up Image And Headings -->
		
		<!-- Sign Up Form -->
		<a-card :bordered="false" class="card-signup header-solid h-full" :bodyStyle="{paddingTop: 0}">
			<p class="text-center my-25 font-semibold text-muted"></p>
			<a-form
				id="components-form-demo-normal-login"
				:model="loginForm"
        :rules="loginRules"
				class="login-form"
				@submit="handleSubmit"
			>
        <a-form-item class="mb-10" :colon="false" name="phoneNumber">
          <a-input v-model="loginForm.phoneNumber" placeholder="请输入手机号" />
        </a-form-item>
        <a-form-item class="mb-5" :colon="false" name="password">
          <a-input v-model="loginForm.password" type="password" placeholder="请输入密码" />
        </a-form-item>
        <a-form-item class="mb-10" name="rememberMe">
          <a-switch v-model="loginForm.rememberMe"/> 记住我
        </a-form-item>
				<a-form-item>
					<a-button type="primary" block html-type="submit" class="login-form-button">
						登录
					</a-button>
				</a-form-item>
			</a-form>
			<p class="font-semibold text-muted text-center">还没有账户？ <router-link to="/register" class="font-bold text-dark">注册</router-link></p>
		</a-card>
		<!-- / Sign Up Form -->

	</div>
</template>

<script>

import Cookies from "js-cookie";
import {decrypt, encrypt} from "@/utils/jsencrypt";

export default {
  name: "sign-in",
  data() {
    return {
      codeUrl: "",
      loginForm: {
        phoneNumber: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: "",
      },
      loginRules: {
        phoneNumber: [
          { required: true, trigger: "blur", message: "请输入您的手机号" }
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" }
        ],
        code: [
          { required: true, trigger: "change", message: "请输入验证码" }
        ],
      },
      loading: false,
      redirect: undefined
    }
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    this.form = this.$form.createForm(this, { name: 'normal_login' });
  },
  created() {
    this.getCookie();
  },
  methods: {
    getCookie() {
      const phoneNumber = Cookies.get("nereids-phoneNumber");
      const password = Cookies.get("nereids-password");
      const rememberMe = Cookies.get('nereids-rememberMe')
      this.loginForm = {
        phoneNumber: phoneNumber === undefined ? this.loginForm.phoneNumber : phoneNumber,
        password: password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
      };
    },
    // Handles input validation after submission.
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, value) => {
        if (!err) {
          if (this.loginForm.rememberMe) {
            Cookies.set("nereids-phoneNumber", this.loginForm.phoneNumber, {expires: 30});
            Cookies.set("nereids-password", encrypt(this.loginForm.password), {expires: 30});
            Cookies.set('nereids-rememberMe', this.loginForm.rememberMe, {expires: 30});
          } else {
            Cookies.remove("nereids-phoneNumber");
            Cookies.remove("nereids-password");
            Cookies.remove('nereids-rememberMe');
          }
          this.$store.dispatch("Login", this.loginForm).then(() => {
            this.$router.push('/profile').catch(() => {
            });
          });
        }
      });
    },
  }
}

</script>

<style lang="scss">
</style>